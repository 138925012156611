import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastErrorSvg, ToastSuccessSvg } from "utils/svgs";
import { router } from "./router";

function App() {
  const state = useSelector((state: any) => state?.userCheck);
  if (state?.isLoggedIn === true) {
    location.href = `${window.location.origin}/login`;
  }
  return (
    <div className="App">
      <RouterProvider router={router} />
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="toast-container-custom"
        toastOptions={{
          success: {
            icon: (
              <div className="toast-container-div">
                <ToastSuccessSvg />
              </div>
            ),
            style: {
              backgroundColor: "#009049",
              color: "#fff",
              fontSize: "16px",
            },
          },
          error: {
            icon: (
              <div className="toast-container-div">
                <ToastErrorSvg />
              </div>
            ),
            style: {
              backgroundColor: "red",
              color: "#fff",
              fontSize: "16px",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
