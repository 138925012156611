/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useEffect, useState } from "react";
import { toastText } from "utils/utils";
import AddShipmentManuallyModal from "./AddShipmentManuallyModel";
import UploadShipmentModal from "./AddShipmentModal";
import ShipmentTable from "./ShipmentTable";
import { ShipmentProps } from "./types";

import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { TableActionHeader } from "components/Global";
import { getApi } from "redux/apis";
import { AddSvg } from "utils/svgs";
import { SEARCHABLE_FIELDS } from "../../../utils/shipment";
import { useSelector } from "react-redux";
import { useInventoryCounts } from "hooks";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShipmentComponent = (props: ShipmentProps) => {
  const { refetchCounts } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [allShipmentData, setAllShipmentData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchFields, setSearchFields] = useState<string[]>(
    SEARCHABLE_FIELDS.map((field) => field.value)
  );
  const [isAddShipmentModalOpen, setIsUploadShipmentModalOpen] =
    useState(false);
  const [isAddShipmentManuallyModalOpen, setIsAddShipmentManuallModalOpen] =
    useState(false);
  const [selectedShipment, setSelectedShipment] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const isServiceCenter =
    profileData?.companyRoles[0]?.role?.roleName === "Service Center Employee";

  const isAdmin =
    profileData?.companyRoles[0]?.role?.roleName === "Company Admin";

  const roleName = profileData?.companyRoles[0]?.role?.roleName;
  const userId = profileData.id;

  const [showAddShipmentButton, setShowAddShipmentButton] = useState(false);

  const serviceCenterAccess = profileData?.serviceCenterAccess || [];
  // const isEmployeeWithoutAccess =
  //   roleName === "Employee" && serviceCenterAccess.length === 0;
  // const [hasServiceCenterAccess, setHasServiceCenterAccess] = useState(false);

  // useEffect(() => {
  //   if (serviceCenterAccess.length > 0) {
  //     setHasServiceCenterAccess(true);
  //   }
  // }, [serviceCenterAccess]);

  const handleCancel = () => {
    setIsUploadShipmentModalOpen(false);
  };

  const showUploadShipmentModal = () => {
    setSelectedRowKeys([]);
    setIsUploadShipmentModalOpen(true);
  };

  const showAddShipmentManuallyModal = () => {
    setSelectedRowKeys([]);
    setIsAddShipmentManuallModalOpen(true);
  };

  const closeAddHolidayManuallyModal = () => {
    setIsAddShipmentManuallModalOpen(false);
    // setEdit(null);
  };

  const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
    setTotalRecords(pagination.total);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortOrder(
      sorter?.order === "ascend" ? "asc" : sorter?.order ? "desc" : ""
    );
    setSortField(sorter.field);
  };

  const fetchAllShipmentDetails = async (params?: any) => {
    try {
      const query = {
        page: params?.pageNumber || currentPage,
        pageSize: params?.pageSize || pageSize,
        sortBy: sortField,
        sortOrder: sortOrder,
        searchValue: searchValue
          ? searchValue
          : params?.searchParams?.value || "",
      };

      setIsLoading(true);
      const response = await getApi("/shipment/get-shipments", query);
      const { total, data: shipmentDetails } = response.data.data;

      setAllShipmentData(shipmentDetails);
      await refetchCounts();
      setTotalRecords(total);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message || "Failed to fetch shipments";
      const errorCode = error.response?.data?.error?.code;

      if (errorCode === 403) {
        toastText("You don't have permission to view shipments", "error");
      } else if (errorCode === 404) {
        toastText("No shipments found", "warning");
      } else {
        toastText(errorMessage, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserAccess = async () => {
    try {
      const response = await getApi(`/users/${userId}`);
      const { serviceCenterAccess } = response.data.data;

      // Hide the button if the role is "Employee" and no service center access is present
      if (
        roleName === "Company Admin" ||
        (roleName === "Employee" && serviceCenterAccess.length > 0)
      ) {
        setShowAddShipmentButton(true); // Show button if Admin or Employee with service center access
      } else {
        setShowAddShipmentButton(false); // Hide button for Service Center Employee or Employee without access
      }
    } catch (error) {
      console.error("Error fetching user access:", error);
      setShowAddShipmentButton(false); // Fallback to hiding the button
    }
  };

  // useEffect(() => {
  //   if (userId) {
  //     fetchUserAccess();
  //   }
  // }, [userId]);
  // useEffect(() => {
  //   const fetchUserAccess = async () => {
  //     try {
  //       const response = await getApi(`/users/${userId}`);
  //       console.log("responseeeeee  : ", response);
  //     } catch (error) {
  //       console.error("Error fetching user access:", error);
  //     }
  //   };

  //   if (userId) {
  //     fetchUserAccess();
  //   }
  // }, [userId]);

  // Define the menu items
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div onClick={() => showUploadShipmentModal()}>
          <UploadOutlined style={{ marginRight: 8 }} />
          Upload Shipments
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => showAddShipmentManuallyModal()}>
          <EditOutlined style={{ marginRight: 8 }} />
          Add Manually
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchAllShipmentDetails();
  }, [searchValue,currentPage, pageSize, sortField, sortOrder]);

  // Add search handlers
  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
    // fetchAllShipmentDetails({
    //   pageNumber: 1,
    //   pageSize,
    //   searchParams: { fields: searchFields, value: value.trim() },
    // });
  };

  const resetButtonHandler = () => {
    setSearchValue("");
    setSearchFields(SEARCHABLE_FIELDS.map((field) => field.value));
    setCurrentPage(1);
    fetchAllShipmentDetails({
      pageNumber: 1,
      pageSize,
      searchParams: { fields: [], value: "" },
    });
  };

  const searchFieldsSelectHandler = (value: string[]) => {
    setSearchFields(value);
    setCurrentPage(1);
    fetchAllShipmentDetails({
      pageNumber: 1,
      pageSize,
      searchParams: { fields: value, value: searchValue.trim() },
    });
  };

  // Row Selection
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys as string[]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record.status !== "READY_TO_SHIP" || isServiceCenter, // Disable rows not in READY_TO_SHIP status
    }),
  };
  return (
    <>
      <div>
        {/* <TableActionHeader title={"Shipment"}>
          {(!isServiceCenter || showAddShipmentButton) && (
            <div>
              <Dropdown menu={{ items }} placement="bottomLeft">
                <button className="btn-blue">
                  <AddSvg /> Add Shipment
                </button>
              </Dropdown>
            </div>
          )}
        </TableActionHeader> */}
        <TableActionHeader title={"Shipment"}>
          {!isServiceCenter && (
            <div>
              <Dropdown menu={{ items }} placement="bottomLeft">
                <button className={`btn-blue`}>
                  <AddSvg /> Add Shipment
                </button>
              </Dropdown>
            </div>
          )}
        </TableActionHeader>

        <UploadShipmentModal
          isUploadModalOpen={isAddShipmentModalOpen}
          handleCancel={handleCancel}
          fetchShipment={fetchAllShipmentDetails}
        />
        <AddShipmentManuallyModal
          isVisible={isAddShipmentManuallyModalOpen}
          handleCancel={closeAddHolidayManuallyModal}
          selectedShipment={selectedShipment}
          setSelectedShipment={setSelectedShipment}
          fetchAllShipmentDetails={fetchAllShipmentDetails}
        />

        <ShipmentTable
          allShipmentData={allShipmentData}
          isLoading={isLoading}
          setSelectedShipment={setSelectedShipment}
          openModalHandler={showAddShipmentManuallyModal}
          totalRecords={totalRecords}
          searchValue={searchValue}
          searchFields={searchFields}
          performSearchHandler={performSearchHandler}
          // searchFieldsSelectHandler={searchFieldsSelectHandler}
          // resetButtonHandler={resetButtonHandler}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          fetchShipments={fetchAllShipmentDetails}
          tableChangeHandler={tableChangeHandler}
          isServiceCenter={isServiceCenter}
          rowSelection={rowSelection}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          sortDirection={sortOrder}
        />
      </div>
    </>
  );
};

export default ShipmentComponent;
