import { Modal, Table, Button } from "antd";
import { FC } from "react";

interface PackagingListModalProps {
  isVisible: boolean;
  packagingData: any[];
  onClose: () => void;
  onGeneratePaySlip: (packageId: string) => void;
  packageId: string;
  loadingPaySlip: Record<string, boolean>; // Add this prop
}

const { Column } = Table;

const PackagingListModal: FC<PackagingListModalProps> = ({
  isVisible,
  packagingData,
  onClose,
  onGeneratePaySlip,
  packageId,
  loadingPaySlip,
}) => {
  return (
    <Modal
      title="Packaging List"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button
          key="generate"
          type="primary"
          size="large"
          onClick={() => onGeneratePaySlip(packageId)} // Pass packageId to onGeneratePaySlip
          loading={!!loadingPaySlip[packageId]}
          style={{ marginTop: "20px" }} // Adds space below the button
        >
          Generate packing slip
        </Button>,
      ]}
      width="80%"
    >
      <Table dataSource={packagingData} rowKey="pallet" pagination={false}>
        <Column
          title="Pallet"
          dataIndex="palletName"
          key="palletName"
         width="11%"
          className="bg-white"
          render={(text, record, index) => {
            // Check if the previous row has the same pallet name
            const sameAsPrevious =
              index > 0 &&
              packagingData[index - 1].palletName === record.palletName;

            return {
              children: text,
              props: {
                rowSpan: sameAsPrevious
                  ? 0
                  : packagingData.filter(
                      (item) => item.palletName === record.palletName
                    ).length,
              },
            };
          }}
        />

        <Column
          title="Pallet Dimensions"
          key="palletDimensions"
          render={(text, record) => {
            const { palletLength, palletWidth, palletHeight } =
              record.palletDimensions || {};

            if (!palletLength && !palletWidth && !palletHeight) {
              // If all three are missing
              return "-";
            }

            // If some are missing, show `-` in their place
            return `${palletLength || "0"} x ${palletWidth || "0"} x ${
              palletHeight || "0"
            }`;
          }}
          width="14%"
          className="bg-white"
        />

        <Column
          title="Pallet Weight"
          dataIndex="palletDimensions"
          key="palletWeight"
          render={(text, record) =>
            record?.palletDimensions?.palletWeight || "-"
          }
          width="11%"
          className="bg-white"
        />
        <Column
          title="Box Range"
          key="boxRange"
          render={(text, record) => {
            const groupedData = packagingData.reduce((acc, curr) => {
              if (!acc[curr.palletName]) acc[curr.palletName] = [];
              acc[curr.palletName].push(curr);
              return acc;
            }, {});

            const currentGroup = groupedData[record.palletName];
            const cumulativeQuantities = currentGroup.map(
              (item: any, idx: any) =>
                currentGroup
                  .slice(0, idx)
                  .reduce(
                    (sum: any, curr: any) => sum + (curr.quantity || 0),
                    0
                  )
            );

            const start =
              cumulativeQuantities[currentGroup.indexOf(record)] + 1;
            const end = start + (record.quantity || 0) - 1;

            return record?.quantity ? `${start}-${end}` : "-";
          }}
          width="10%"
          className="bg-white"
        />

        {/* <Column
          title="Box Range"
          key="boxRange"
          render={(text, record) => {
            const totalQuantity = record?.quantity;
            return totalQuantity ? `1-${totalQuantity}` : "-";
          }}
          width="10%"
          className="bg-white"
        /> */}

        <Column
          title="Item"
          dataIndex="itemName"
          key="itemName"
          render={(text, record) => record?.itemName || "-"}
          width="14%"
          className="bg-white"
        />
        <Column
          title="Model"
          dataIndex="modelName"
          key="modelName"
          render={(text, record) => record?.modelName || "-"}
          width="9%"
          className="bg-white"
        />
        <Column
          title="Qty Per Box"
          dataIndex="items"
          key="qtyPerBox"
          render={(text, record) => record?.quantityPerBox || "-"}
          width="10%"
          className="bg-white"
        />

        {/* <Column
          title="Box Dimensions"
          render={(text, record) => {
            const { length, width, height } = record;
            return length && width && height
              ? `${length} x ${height} x ${width}`
              : "-";
          }}
          width="15%"
          className="bg-white"
        /> */}
        <Column
          title="Box Dimensions"
          render={(text, record) => {
            const { length, width, height } = record;

            if (!length && !width && !height) {
              // If all three dimensions are missing
              return "-";
            }

            // If some dimensions are missing, show `-` in their place
            return `${length || "0"} x ${height || "0"} x ${width || "0"}`;
          }}
          width="13%"
          className="bg-white"
        />

        <Column
          title="Box Weight"
          render={(text, record) => record?.weight?.toFixed(2) || "-"} // Use weight for box weight
          width="9%"
          className="bg-white"
        />
      </Table>
    </Modal>
  );
};

export default PackagingListModal;
