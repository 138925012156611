import { Button, Form, InputNumber, Select, Table } from "antd";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { useEffect, useState } from "react";
import { getApi, postApi, putApi } from "redux/apis";
import { DeleteActionSvg } from "utils/svgs";
import { capitalizeFirstLetter, toastText } from "utils/utils";
import { invalidText } from "utils/utils";
import PalletTypeModal from "../PalletTypeModal";
import SearchAndFilter from "../SearchAndFilter";
import { useNavigate } from "react-router-dom";

const checkForMissingValues = (data: any) => {
  return data.map((row: any) => {
    const isItemError = !row.itemName || !row.itemId;
    const isQuantityError = invalidText(row.quantity);
    const isContainerTypeError =
      !row.containerType || invalidText(row.containerType);

    return {
      ...row,
      isItemError,
      isQuantityError,
      isContainerTypeError,
    };
  });
};
const ShippingModal = (props: any) => {
  const { packageId, isOpen, handleShippingCancel } = props;

  const navigate = useNavigate();

  const [isPalletTypeModalOpen, setIsPalletTypeModalOpen] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [palletsLoading, setPalletsLoading] = useState(false);
  const [palletCounter, setPalletCounter] = useState(1);
  const [mixedPalletCounter, setMixedPalletCounter] = useState(1);
  const [customBoxCounter, setCustomBoxCounter] = useState(4);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState<any>(null);
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false);
  const [items, setItems] = useState<
    Array<{
      name: string;
      id: number;
      quantity: number;
      listID: string;
      modelName: string;
    }>
  >([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const [finalItemStatus, setFinalItemStatus] = useState<any>([]);

  const [isSaveAndDraftLoading, setIsSaveAndDraftLoading] = useState(false);
  const [isCreatePackingLoading, setIsCreatePackingLoading] = useState(false);

  const [isDiscrepancyModalOpen, setIsDiscrepancyModalOpen] = useState(false);
  const [discrepancies, setDiscrepancies] = useState<string[]>([]);

  //  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => setIsPalletTypeModalOpen(true);
  const closeModal = () => setIsPalletTypeModalOpen(false);

  // const reorderPalletsByItem = (pallets: any[]) => {
  //   // Separate pallets and mixed pallets
  //   const regularPallets = pallets.filter((p) => p.palletType === "PALLET");
  //   const mixedPallets = pallets.filter((p) => p.palletType !== "PALLET");

  //   const itemTotals = calculateItemTotals(regularPallets);

  //   // Group regular pallets by item name
  //   const groupedPallets = regularPallets.reduce((groups: any, pallet: any) => {
  //     const key = pallet.itemName;
  //     if (!groups[key]) {
  //       groups[key] = [];
  //     }
  //     groups[key].push(pallet);
  //     return groups;
  //   }, {});

  //   // Group mixed pallets by item name
  //   const groupedMixPallets = mixedPallets.reduce(
  //     (groups: any, pallet: any) => {
  //       const key = pallet.itemName;
  //       if (!groups[key]) {
  //         groups[key] = [];
  //       }
  //       groups[key].push(pallet);
  //       return groups;
  //     },
  //     {}
  //   );

  //   // Sort each group by pallet number and rename them sequentially
  //   let palletCounter = 1;
  //   const reorderedPallets = Object.values(groupedPallets)
  //     .flat()
  //     .map((pallet: any) => {
  //       const itemTotal = itemTotals[pallet.itemId];
  //       const remainingItems = itemTotal
  //         ? itemTotal.soQuantity - itemTotal.totalQuantity
  //         : pallet.soQuantity;

  //       return {
  //         ...pallet,
  //         palletName: `Pallet ${palletCounter++}`,
  //         remainingItems: remainingItems,
  //       };
  //     });

  //   let mixPalletCounter = 1;
  //   const reorderedMixPallets = Object.values(groupedMixPallets)
  //     .flat()
  //     .map((pallet: any) => {
  //       const itemTotal = itemTotals[pallet.itemId];
  //       const remainingItems = itemTotal
  //         ? itemTotal.soQuantity - itemTotal.totalQuantity
  //         : pallet.soQuantity;

  //       return {
  //         ...pallet,
  //         palletName: `Mixed Pallet ${mixPalletCounter++}`,
  //         remainingItems: remainingItems,
  //       };
  //     });

  //   // Return combined reordered pallets and unchanged mixed pallets
  //   return [...reorderedPallets, ...reorderedMixPallets];
  // };

  const reorderItemHandler = (pallets: any[]) => {
    // Separate pallets and mixed pallets
    const regularPallets = pallets.filter((p) => p.palletType === "PALLET");
    const mixedPallets = pallets.filter((p) => p.palletType !== "PALLET");
    const itemTotals = calculateItemTotals(regularPallets);
    // Group regular pallets by item name
    const groupedPallets = regularPallets.reduce((groups: any, pallet: any) => {
      const key = pallet.itemName;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(pallet);
      return groups;
    }, {});

    // Sort each group by pallet number and rename them sequentially
    let palletCounter = 1;
    const reorderedPallets = Object.values(groupedPallets)
      .flat()
      .map((pallet: any) => {
        const itemTotal = itemTotals[pallet.itemId];
        const remainingItems = itemTotal
          ? itemTotal.soQuantity - itemTotal.totalQuantity
          : pallet.soQuantity;

        return {
          ...pallet,
          palletName: `Pallet ${palletCounter++}`,
          remainingItems: remainingItems > 0 ? remainingItems : 0,
        };
      });

    // Update remainingItems for mixed pallets
    const updatedMixedPallets = mixedPallets.map((pallet: any) => {
      const itemTotal = itemTotals[pallet.itemId];
      const remainingItems = itemTotal
        ? itemTotal.soQuantity - itemTotal.totalQuantity
        : pallet.soQuantity;

      return {
        ...pallet,
        remainingItems: remainingItems > 0 ? remainingItems : 0,
      };
    });

    // Return combined reordered pallets and updated mixed pallets
    return [...reorderedPallets, ...updatedMixedPallets];
  };

  const calculateItemTotals = (pallets: any[]) => {
    // Group pallets by itemId and calculate totals
    const itemTotals = pallets.reduce((totals: any, pallet: any) => {
      if (!pallet.itemId) return totals;

      if (!totals[pallet.itemId]) {
        totals[pallet.itemId] = {
          totalQuantity: 0,
          soQuantity: pallet.soQuantity,
        };
      }

      // Add this pallet's quantity to the total
      totals[pallet.itemId].totalQuantity += pallet.quantity || 0;

      return totals;
    }, {});

    return itemTotals;
  };

  // Add New Pallet or Mix Pallet from modal
  const onSavePalletType = (selectedType: string) => {
    const newId = Date.now();

    const defaultDimensions = {
      id: `dim-${newId}`,
      palletLength: 0,
      palletWidth: 0,
      palletHeight: 0,
      palletWeight: 0,
    };

    const palletName =
      selectedType === "PALLET"
        ? `Pallet ${palletCounter}`
        : `Mixed Pallet ${mixedPalletCounter}`;

    const newRow = {
      id: newId,
      palletName,
      itemName: "",
      soQuantity: 0,
      modelName: "",
      palletType: selectedType,
      containerType: "",
      quantity: 0,
      quantityPerBox: 0,
      totalQuantity: 0,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      remainingItems: 0,
      palletDimensions: defaultDimensions, // Initialize with default dimensions
      isDimensionsRow: false,
    };

    const _data = reorderItemHandler([...tableData, newRow]);

    setTableData(_data);
    // setTableData([...tableData, newRow]);

    if (selectedType === "PALLET") {
      setPalletCounter((prev) => prev + 1);
    } else {
      setMixedPalletCounter((prev) => prev + 1);
    }
    collapseAllRows();
    setIsPalletTypeModalOpen(false);
  };

  // Add New Pallet with "PALLET" type
  const createNewPallet = (record: any) => {
    const newRow = {
      id: Date.now(),
      palletName: `Pallet ${palletCounter}`,
      palletType: "PALLET",
      itemName: record.itemName,
      itemId: record.itemId,
      soQuantity: record.soQuantity,
      modelName: record.modelName,
      containerType: "",
      quantity: 0,
      quantityPerBox: 0,
      totalQuantity: 0,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      remainingItems: record.remainingItems,
      palletDimensions: null,
      isDimensionsRow: false,
    };

    const lastPallet = getLastPalletByItem(tableData, record.itemId);
    const lastPalletIndex = lastPallet
      ? tableData.findIndex((row) => row.id === lastPallet.id)
      : -1;

    let newTableData;
    if (lastPalletIndex >= 0) {
      newTableData = [
        ...tableData.slice(0, lastPalletIndex + 1),
        newRow,
        ...tableData.slice(lastPalletIndex + 1),
      ];
    } else {
      newTableData = [...tableData, newRow];
    }

    const _data = reorderItemHandler(newTableData);

    // const reorderedData = reorderPalletsByItem(newTableData);
    collapseAllRows();
    setTableData(_data);
    setPalletCounter((prev) => prev + 1);
  };

  const handleItemChange = async (value: string, record: any) => {
    const selectedItem = items.find((item: any) => item.id === value);
    if (selectedItem) {
      const _tableData = tableData.map((item) => {
        if (item.id === record.id) {
          return {
            ...item,
            itemId: value,
            itemName: selectedItem.name,
            soQuantity: selectedItem.quantity,
            modelName: selectedItem.modelName,
            containerType: null,
            quantity: 0,
            quantityPerBox: 0,
            totalQuantity: 0,
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            remainingItems: 0,
            isItemError: selectedItem.name ? false : true,
          };
        } else {
          return item;
        }
      });

      const _data = reorderItemHandler(_tableData);

      setTableData(_data);
    }
  };

  const handleContainerTypeChange = async (value: string, record: any) => {
    try {
      setPalletsLoading(true);

      if (value === "ITEM" || value === "MASTERCARTON") {
        const response = await getApi(`/shipping/item-dimensions`, {
          itemId: record.itemId,
          type: value,
        });

        if (response.data.data) {
          const dimensions = response.data.data;

          const _tableData = tableData.map((item) => {
            if (item.id === record.id) {
              return {
                ...item,
                quantityPerBox: dimensions.boxQuantity,
                totalQuantity: dimensions.boxQuantity * item.quantity,
                length: dimensions.length,
                weight: dimensions.weight,
                width: dimensions.width,
                height: dimensions.height,
                palletDimensions: dimensions.palletDimensions,
                containerType: value,
                isContainerTypeError: value ? false : true,
              };
            } else {
              return item;
            }
          });

          setTableData(_tableData);
        }
      } else {
        const _tableData = tableData.map((item) => {
          if (item.id === record.id) {
            return {
              ...item,
              quantityPerBox: 0,
              totalQuantity: 0,
              length: 0,
              width: 0,
              height: 0,
              weight: 0,
              containerType: "BOX",
              containerTypeName: value,
              isContainerTypeError: item.id ? false : true,
            };
          } else {
            return item;
          }
        });
        setTableData(_tableData);
      }
    } catch (err) {
      console.log("Something went wrong in fetching dimensions: ", err);
    } finally {
      setPalletsLoading(false);
    }
  };

  const handleQuantityChange = (value: number, record: any) => {
    const totalQty = (value || 0) * (record.quantityPerBox || 0);

    // Calculate the total quantity for the given itemId
    const itemTotal = tableData
      .filter(
        (_pallet) =>
          _pallet.itemId === record.itemId && _pallet.id !== record.id
      )
      .reduce((sum, pallet) => sum + (pallet.quantity || 0), 0);

    const totalRemainingItems = record.soQuantity - (itemTotal + (value || 0));

    // Update the table data
    const updatedData = tableData.map((_pallet) => {
      if (_pallet.id === record.id) {
        // Update the specific pallet with new values
        return {
          ..._pallet,
          quantity: value || 0,
          totalQuantity: totalQty,
          remainingItems: totalRemainingItems > 0 ? totalRemainingItems : 0,
          // isQuantityError: value < 1 || value > record.soQuantity? true : false,
          isQuantityError: false,
        };
      }

      if (_pallet.itemId === record.itemId) {
        // Update remaining items for pallets with the same itemId
        return {
          ..._pallet,
          remainingItems: totalRemainingItems > 0 ? totalRemainingItems : 0,
        };
      }

      return _pallet; // No changes for other pallets
    });

    setTableData(updatedData);
  };

  const deleteHandler = () => {
    if (selectedShipping) {
      setIsDeleteLoading(true);

      const filteredData = tableData.filter(
        (row) => row.id !== selectedShipping.id
      );

      let palletCount = 1;
      let mixPalletCount = 1;
      let latestMixPalletName = "";

      const finalData = filteredData.map((item: any) => {
        if (item.palletType === "PALLET") {
          item.palletName = `Pallet ${palletCount}`;
          palletCount++;
        } else if (item.palletType === "MIXPALLET") {
          if (item.palletName == latestMixPalletName) {
            item.palletName = latestMixPalletName;
          } else {
            latestMixPalletName = item.palletName;
            item.palletName = latestMixPalletName;
            mixPalletCount++;
          }
        }
        return item;
      });

      setPalletCounter(palletCount);
      setMixedPalletCounter(mixPalletCount);
      setTableData(finalData);
      setIsDeleteLoading(false);
      setSelectedShipping(null);
      setIsModalOpen(false);
    }
  };

  const addToExistingPallet = (existingPallet: any, record: any) => {
    const newRow = {
      id: Date.now(),
      palletName: existingPallet.palletName,
      palletType: "MIXPALLET",
      itemName: "",
      itemId: null,
      modelName: "",
      soQuantity: 0,
      containerType: "",
      quantity: 0,
      quantityPerBox: 0,
      totalQuantity: 0,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      remainingItems: 0,
      palletDimensions: existingPallet.palletDimensions, // Use the same dimensions object
      isDimensionsRow: false,
    };

    const filterData = tableData.filter(
      (item) => item.isDimensionsRow === false
    );

    setTableData([...filterData, newRow]);
    collapseRowsByPallet(record.palletName);
  };

  const addToCurrentPallet = (record: any) => {
    const newRow = {
      id: Date.now(),
      palletName: record.palletName,
      palletType: "MIXPALLET",
      itemName: "",
      itemId: null,
      modelName: "",
      soQuantity: 0,
      containerType: "",
      quantity: 0,
      quantityPerBox: 0,
      totalQuantity: 0,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      remainingItems: 0,
      palletDimensions: record.palletDimensions || {
        id: `dim-${Date.now()}`,
        palletLength: 0,
        palletWidth: 0,
        palletHeight: 0,
        palletWeight: 0,
      },
      isDimensionsRow: false,
    };

    const filterData = tableData.filter(
      (item) => item.isDimensionsRow === false
    );

    setTableData([...filterData, newRow]);
    collapseRowsByPallet(record.palletName);
  };

  const handleDimensionChange = (
    value: number | null,
    field: string,
    record: any
  ) => {
    const dimensionField = `pallet${capitalizeFirstLetter(field)}`;

    let updatedData = [];

    if (record.isDimensionsRow) {
      if (record.palletType === "MIXPALLET") {
        updatedData = tableData.map((item) => {
          if (
            item.palletType === "MIXPALLET" &&
            item.palletName === record.palletName
          ) {
            return {
              ...item,
              palletDimensions: {
                ...item.palletDimensions,
                [dimensionField]: value,
              },
            };
          }
          return item;
        });
      } else {
        updatedData = tableData.map((item) =>
          item.id === record.id
            ? {
                ...item,
                [field]: value || 0,
              }
            : item
        );
      }
    } else {
      updatedData = tableData.map((item) => {
        if (item.id === record.id) {
          const newTotalQuantity =
            field === "quantityPerBox"
              ? (value || 0) * (item.quantity || 0)
              : item.totalQuantity;

          return {
            ...item,
            [field]: value || 0,
            totalQuantity: newTotalQuantity,
          };
        }
        return item;
      });
    }
    setTableData(updatedData);
  };

  const handleSaveAndDraft = () => {
    const validatedData = checkForMissingValues(tableData);
    const hasErrors = validatedData.some(
      (pallet: any) =>
        pallet.isContainerTypeError ||
        pallet.isItemError ||
        pallet.isQuantityError
    );

    if (hasErrors) {
      toastText(
        "Pallet item name, container type, and quantity can't be empty. Please add the missing details before saving.",
        "error"
      );
      setTableData(validatedData);
      // transformPalletData(validatedData);
      return;
    }
    const data = transformPalletData(validatedData);
    savePallets(data);
  };

  const transformPalletData = (inputData: any) => {
    // Initialize the output structure
    const output = {
      packagingId: packageId,
      pallets: [],
    };

    // Group items by palletName and palletType
    const groupedPallets = inputData.reduce((acc: any, item: any) => {
      const key = `${item.palletType}_${item.palletName}`;
      if (!acc[key]) {
        acc[key] = {
          items: [],
          palletType: item.palletType,
          palletName: item.palletName,
          // quantityPerBox: item.quantityPerBox,
          isQuantityError: item.isQuantityError || false, // Add error flag
          isContainerTypeError: item.isContainerTypeError || false, // Add error flag
          isItemError: item.isItemError || false, // Add error flag
        };
      }

      // Create item object with only required fields
      const transformedItem: any = {
        itemId: item.itemId,
        quantity: item.quantity,
        containerType: item.containerType,
        containerTypeName: item.containerTypeName,
        quantityPerBox: item.quantityPerBox,
        length: item.length,
        width: item.width,
        height: item.height,
        weight: item.weight,
        isQuantityError: item.isQuantityError || false, // Include error flag
        isContainerTypeError: item.isContainerTypeError || false, // Include error flag
        isItemError: item.isItemError || false, // Include error flag
      };

      // For PALLET type, add remainingItems if present
      if (item.palletType === "PALLET" && "remainingItems" in item) {
        transformedItem.remainingItems =
          item.remainingItems > 0 ? item.remainingItems : 0;
      }

      if (
        item.palletType === "MIXPALLET" &&
        "remainingItems" in item &&
        item.remainingItems !== null
      ) {
        transformedItem.remainingItems =
          item.remainingItems > 0 ? item.remainingItems : 0;
      }

      // For PALLET type, include dimensions directly in the pallet
      if (item.palletDimensions) {
        acc[key].palletDimensions = {
          length:
            item.palletDimensions.palletLength || item.palletDimensions.length,
          width:
            item.palletDimensions.palletWidth || item.palletDimensions.width,
          height:
            item.palletDimensions.palletHeight || item.palletDimensions.height,
          weight:
            item.palletDimensions.palletWeight || item.palletDimensions.weight,
        };
      }

      // Add the transformed item
      if (item.palletType === "PALLET") {
        // For PALLET type, add items directly to the pallet object
        Object.assign(acc[key], transformedItem);
      } else {
        // For MIXPALLET type, add items to the items array
        acc[key].items.push(transformedItem);
      }

      return acc;
    }, {});

    // Convert grouped pallets to array format
    output.pallets = Object.values(groupedPallets);

    return output;
  };

  // Cancel operation
  const handleCancel = () => {
    setIsDeleteLoading(false);
    setSelectedShipping(null);
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsDeleteLoading(false);
    setSelectedShipping(null);
    setIsModalOpen(false);
  };

  const handleOrderClick = () => {
    setIsOrderStatusModalOpen(true);
  };

  // Handler for closing the order status modal
  const handleOrderStatusModalClose = () => {
    setIsOrderStatusModalOpen(false);
  };

  const getLastPalletByItem = (data: any[], itemId: string) => {
    const pallets = data
      .filter((row) => row.itemId === itemId && row.palletType === "PALLET")
      .sort((a, b) => {
        const aNum = parseInt(a.palletName.split(" ")[1]);
        const bNum = parseInt(b.palletName.split(" ")[1]);
        return bNum - aNum;
      });
    return pallets[0];
  };

  const collapseRowsByPallet = (palletName: string) => {
    setExpandedRowKeys((prevKeys: any) =>
      prevKeys.filter((key: any) => {
        const row = tableData.find((item) => item.id === key);
        return row?.palletName !== palletName;
      })
    );
  };

  const collapseAllRows = () => {
    setExpandedRowKeys([]);
  };

  const getLastMixPalletRowByPallet = (palletName: string) => {
    // Filter rows belonging to the specified Mixed Pallet
    const mixedPalletRows = tableData.filter(
      (row) => row.palletName === palletName
    );

    // Return the last row, or undefined if no rows are found
    return mixedPalletRows[mixedPalletRows.length - 1];
  };

  const getExpandedRowRender = (record: any) => {
    const existingPallets = tableData
      .filter(
        (item) =>
          item.palletType === "MIXPALLET" &&
          item.palletName !== record.palletName
      )
      .filter(
        (item, index, self) =>
          self.findIndex((i) => i.palletName === item.palletName) === index
      );

    // const isLastPalletForItem =
    //   getLastPalletByItem(tableData, record.itemId)?.id === record.id;

    const isLastMixPalletForItem =
      getLastMixPalletRowByPallet(record.palletName)?.id === record.id;

    return (
      <div style={{ padding: "10px 40px" }}>
        <div
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "4rem",
            border: "1px solid grey",
            borderRadius: "12px",
          }}
        >
          <p>
            Remaining Items:{" "}
            {record.remainingItems < 0 ? 0 : record.remainingItems}
          </p>
          {
            // {isLastPalletForItem &&
            record.palletType === "PALLET" &&
              record.quantity > 0 &&
              record.quantity < record.soQuantity && (
                <Button
                  type="primary"
                  onClick={() => createNewPallet(record)}
                  style={{ marginRight: 8 }}
                  disabled={!record.remainingItems}
                >
                  Create a new Pallet
                </Button>
              )
          }

          {record?.palletType === "MIXPALLET" && isLastMixPalletForItem && (
            <Button
              type="primary"
              onClick={() => {
                addToCurrentPallet(record);
              }}
              style={{ marginRight: 8 }}
            >
              Add Item to Current Pallet
            </Button>
          )}

          {record?.palletType === "MIXPALLET" &&
            existingPallets.length > 0 &&
            isLastMixPalletForItem && (
              <Select
                placeholder="Add to existing pallet"
                style={{ width: 200 }}
                onChange={(value) => {
                  const selectedPallet = existingPallets.find(
                    (p) => p.id === value
                  );
                  if (selectedPallet) {
                    addToExistingPallet(selectedPallet, record);
                  }
                }}
                disabled={!record.remainingItems}
              >
                {existingPallets.map((pallet) => (
                  <Select.Option key={pallet.id} value={pallet.id}>
                    {pallet.palletName}
                  </Select.Option>
                ))}
              </Select>
            )}
        </div>
      </div>
    );
  };

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      if (record?.palletType === "MIXPALLET") {
        setExpandedRowKeys([record.id]);
      } else {
        setExpandedRowKeys((prevKeys: any) => [...prevKeys, record.id]);
      }
      // setExpandedRowKeys([record.id]);
    } else {
      setExpandedRowKeys((prevKeys: any) =>
        prevKeys.filter((key: any) => key !== record.id)
      );
    }
  };

  //  API Calls

  const fetchItems = async (packageId: string) => {
    try {
      setPalletsLoading(true);
      const response = await getApi("/shipping/item-options", {
        packageId,
      });

      setItems(response.data.data);
      await fetchPallets(packageId, response.data.data);
    } catch (error) {
      console.error("Failed to fetch items:", error);
    } finally {
      setPalletsLoading(false);
    }
  };

  const fetchPallets = async (packageId: string, _items: any) => {
    try {
      setPalletsLoading(true);
      const response = await getApi("/pallets", {
        packageId,
      });
      if (response.data.data && response.data.data.pallets.length > 0) {
        const _data = response.data.data.pallets.map((pallet: any) => {
          const soQty = _items.find((_item: any) => _item.id === pallet.itemId);

          return {
            ...pallet,
            soQuantity: soQty ? soQty.quantity : 0,
          };
        });

        setPalletCounter(
          response.data.data?.pallets?.filter(
            (item: any) =>
              item.palletType === "PALLET" && item.isDimensionsRow === false
          ).length + 1
        );
        setMixedPalletCounter(
          response.data.data?.pallets?.filter(
            (item: any) =>
              item.palletType === "MIXPALLET" && item.isDimensionsRow === false
          ).length + 1
        );
        setTableData(_data);
        // setTableData(response.data.data.pallets);
      }
    } catch (error) {
      console.error("Failed to fetch items:", error);
    } finally {
      setPalletsLoading(false);
    }
  };

  const savePallets = async (data: any) => {
    // Set loader state to true
    setIsSaveAndDraftLoading(true);

    try {
      const response = await postApi("/pallets", data);
      const message = response?.data?.message || "Pallet saved successfully";
      toastText(message, "success");
      handleShippingCancel();
    } catch (err) {
      toastText("Something went wrong in saving pallets", "error");
    } finally {
      // Stop loader after API call finishes
      setIsSaveAndDraftLoading(false);
    }
  };

  const completePackingList = async (data: any) => {
    try {
      setIsCreatePackingLoading(true);
      // const data = { packagingId: packageId };
      const response = await putApi("/packing-list/status", data);
      const message =
        response?.data?.message || "Packing List created successfully";
      toastText(message, "success");
      handleShippingCancel();
    } catch (err) {
      toastText("Something went wrong in creating packing list", "error");
    } finally {
      setIsCreatePackingLoading(false);
    }
  };

  const handlePackingList = () => {
    const validatedData = checkForMissingValues(tableData);
    const hasErrors = validatedData.some(
      (pallet: any) =>
        pallet.isContainerTypeError ||
        pallet.isItemError ||
        pallet.isQuantityError
    );

    if (hasErrors) {
      toastText(
        "Pallet item name, container type, and quantity can't be empty. Please add the missing details before creating packing list.",
        "error"
      );
      setTableData(validatedData);
      transformPalletData(validatedData);
      return;
    }
    setTableData(validatedData);
    return true;
  };

  const columns = [
    {
      title: "Pallet",
      dataIndex: "palletName",
      key: "palletName",
      width: "140px",
      render: (text: string, record: any) =>
        record.isDimensionsRow ? null : text,
    },
    {
      title: "Item",
      dataIndex: "itemName",
      key: "itemName",
      width: "200px",
      render: (_: any, record: any) => {
        if (record.isDimensionsRow) return null;

        // Error flags
        const isItemError = record.isItemError;
        return (
          <Form.Item
            validateStatus={isItemError ? "error" : ""}
            style={{ width: "100%" }}
          >
            <Select
              value={record.itemName || undefined}
              onChange={(value) => handleItemChange(value, record)}
              placeholder="Select item"
            >
              {items?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: "SO Qty",
      dataIndex: "soQuantity",
      key: "soQuantity",
      width: "110px",
      render: (text: string, record: any) =>
        record.isDimensionsRow ? null : text,
    },
    {
      title: "Model",
      dataIndex: "modelName",
      key: "modelName",
      width: "140px",
      render: (text: string, record: any) =>
        record.isDimensionsRow ? null : text,
    },
    {
      title: "Box/Item/MC",
      dataIndex: "containerType",
      key: "containerType",
      width: "180px",
      render: (text: any, record: any) => {
        if (record.isDimensionsRow) return null;

        const isContainerTypeError = record.isContainerTypeError;

        // Extract the current box number from containerTypeName (e.g., Box6 -> 6)
        const currentBoxNumber =
          record.containerType === "BOX" && record.containerTypeName
            ? parseInt(record.containerTypeName.replace("Box ", ""), 10)
            : 0;

        // Determine the total number of boxes to render (e.g., up to Box6)
        const totalBoxes = Math.max(customBoxCounter, currentBoxNumber || 0);

        return (
          <div className="flex items-center gap-2">
            <Form.Item
              validateStatus={isContainerTypeError ? "error" : ""}
              style={{ width: "100%" }}
            >
              <Select
                value={
                  record.containerType === "BOX"
                    ? record.containerTypeName
                    : text || undefined
                }
                // value={text || undefined}
                onChange={(value) => handleContainerTypeChange(value, record)}
                placeholder="Select type"
                disabled={!record.itemName}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {record?.palletType === "MIXPALLET" && (
                      <div style={{ padding: "10px" }}>
                        <Button
                          onClick={() => {
                            setCustomBoxCounter((prev) => prev + 1);
                          }}
                          size="small"
                          type="default"
                          className="w-full"
                        >
                          + Add New Box
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              >
                {record?.palletType !== "MIXPALLET" ? (
                  <>
                    <Select.Option value="ITEM">Item</Select.Option>
                    <Select.Option value="MASTERCARTON">
                      Master Carton
                    </Select.Option>
                  </>
                ) : (
                  <>
                    <Select.Option value="ITEM">Item</Select.Option>
                    <Select.Option value="MASTERCARTON">
                      Master Carton
                    </Select.Option>
                    {Array.from({ length: totalBoxes }).map((_, index) => (
                      <Select.Option
                        key={`Box ${index + 1}`}
                        value={`Box ${index + 1}`}
                      >
                        Box {index + 1}
                      </Select.Option>
                    ))}
                  </>
                )}
              </Select>
            </Form.Item>
          </div>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "120px",
      render: (text: any, record: any) => {
        if (record.isDimensionsRow) return null;

        return (
          <Form.Item style={{ margin: 0 }}>
            <InputNumber
              value={text === 0 ? 0 : text}
              // max={999999999}
              maxLength={9}
              onBlur={(e) => {
                let value = Number(e.target.value);

                value = Math.round(value);

                value = value < 0 ? 0 : value;

                handleQuantityChange(value, record);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  let value = Number(e.target.value);

                  value = Math.round(value);

                  value = value < 0 ? 0 : value;

                  handleQuantityChange(value, record);
                }
              }}
              style={{ width: "100%" }}
              disabled={!record.containerType}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "Qty per Box",
      dataIndex: "quantityPerBox",
      key: "quantityPerBox",
      width: "120px",
      render: (text: any, record: any) => {
        if (record.isDimensionsRow) {
          return {
            children: "Pallet Dimensions",
            props: { colSpan: 2 },
          };
        }

        return (record.containerType === "BOX" || record.isEditableRow) &&
          !record.isDimensionsRow ? (
          <InputNumber
            value={text || 0}
            style={{ width: "100%" }}
            // max={999999999}
            maxLength={9}
            onBlur={(e: any) => {
              let value = Number(e.target.value);

              value = Math.round(value);

              value = value < 0 ? 0 : value;
              handleDimensionChange(value, "quantityPerBox", record);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                let value = Number(e.target.value);

                value = Math.round(value);

                value = value < 0 ? 0 : value;

                handleDimensionChange(value, "quantityPerBox", record);
              }
            }}
            min={0}
          />
        ) : (
          text
        );
      },
    },
    {
      title: "Total Qty",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      width: "120px",
      render: (text: any, record: any) => {
        if (record.isDimensionsRow) {
          return { props: { colSpan: 0 } };
        }
        return text;
      },
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      width: "120px",
      render: (text: any, record: any) =>
        record.containerType === "BOX" || record.isEditableRow ? (
          <InputNumber
            value={text || 0}
            precision={2}
            step={0.01}
            maxLength={9}
            onBlur={(e: any) => {
              const newValue = (e.target.value || "")
                .toString()
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1")
                .replace(/^(\d*\.\d{2}).*/, "$1");

              handleDimensionChange(newValue, "length", record);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                const newValue = (e.target.value || "")
                  .toString()
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1")
                  .replace(/^(\d*\.\d{2}).*/, "$1");

                handleDimensionChange(newValue, "length", record);
              }
            }}
            min={0}
          />
        ) : (
          text
        ),
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
      width: "120px",
      render: (text: any, record: any) =>
        record.containerType === "BOX" || record.isEditableRow ? (
          <InputNumber
            value={text || 0}
            precision={2}
            step={0.01}
            maxLength={9}
            onBlur={(e: any) => {
              const newValue = (e.target.value || "")
                .toString()
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1")
                .replace(/^(\d*\.\d{2}).*/, "$1");

              handleDimensionChange(newValue, "width", record);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                const newValue = (e.target.value || "")
                  .toString()
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1")
                  .replace(/^(\d*\.\d{2}).*/, "$1");

                handleDimensionChange(newValue, "width", record);
              }
            }}
            min={0}
          />
        ) : (
          text
        ),
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
      width: "120px",
      render: (text: any, record: any) =>
        record.containerType === "BOX" || record.isEditableRow ? (
          <InputNumber
            value={text || 0}
            precision={2}
            step={0.01}
            maxLength={9}
            onBlur={(e: any) => {
              const sanitizedValue = (e.target.value || "")
                .toString()
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1")
                .replace(/^(\d*\.\d{2}).*/, "$1");

              handleDimensionChange(sanitizedValue, "height", record);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                const sanitizedValue = (e.target.value || "")
                  .toString()
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1")
                  .replace(/^(\d*\.\d{2}).*/, "$1");

                handleDimensionChange(sanitizedValue, "height", record);
              }
            }}
            min={0}
          />
        ) : (
          text
        ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      width: "120px",
      render: (text: any, record: any) =>
        record.containerType === "BOX" || record.isEditableRow ? (
          <InputNumber
            value={text || 0}
            min={0}
            precision={2}
            step={0.01}
            maxLength={9}
            onBlur={(e: any) => {
              const newValue = (e.target.value || "")
                .toString()
                .replace(/[^0-9.]/g, "")
                .replace(/(\..*?)\..*/g, "$1")
                .replace(/^(\d*\.\d{2}).*/, "$1");

              handleDimensionChange(newValue, "weight", record);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                const newValue = (e.target.value || "")
                  .toString()
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1")
                  .replace(/^(\d*\.\d{2}).*/, "$1");

                handleDimensionChange(newValue, "weight", record);
              }
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "100px",

      render: (_: any, record: any) => {
        if (record.isDimensionsRow) return null;
        return (
          <div
            onClick={() => {
              setSelectedShipping(record);
              setIsModalOpen(true);
              // deleteHandler();
            }}
            style={{ cursor: "pointer" }}
          >
            <DeleteActionSvg />
          </div>
        );
      },
    },
  ];

  // Merge Pallet column cells based on MIXPALLET IDs
  // const mergedColumns: any = columns.map((col) => {
  //   if (col.key === "palletName") {
  //     return {
  //       ...col,
  //       onCell: (record: any, index: number) => {
  //         if (record.palletType === "PALLET") return { rowSpan: 1 };

  //         if (record.isDimensionsRow) {
  //           return { rowSpan: 1 };
  //         }

  //         // Find consecutive rows with the same pallet name
  //         const samePalletRows = transformedData.filter(
  //           (item) =>
  //             !item.isDimensionsRow && item.palletName === record.palletName
  //         );
  //         const firstRowIndex = transformedData.findIndex(
  //           (item) => !item.isDimensionsRow && item.id === samePalletRows[0]?.id
  //         );

  //         // const isExpanded = expandedRowKeys.includes(record.id);

  //         // if (isExpanded) {
  //         //   return { rowSpan: 1 }; // Show the pallet name for all rows when expanded
  //         // }

  //         // Calculate if this is the first row of the group
  //         const isFirstRow = index === firstRowIndex;

  //         return {
  //           rowSpan: isFirstRow ? samePalletRows.length : 0,
  //         };
  //       },
  //     };
  //   }
  //   return col;
  // });

  const getItemWiseData = (itemArray: any, palletArray: any) => {
    return itemArray.map((item: any) => {
      // Get all pallets for this itemId
      const palletsForItem = palletArray.filter(
        (pallet: any) => pallet.itemId === item.id
      );

      // Calculate total packed quantity from pallets
      const totalPalletQuantity = palletsForItem.reduce(
        (sum: number, pallet: any) => sum + pallet.totalQuantity,
        0
      );

      // Determine the packing status
      const status =
        totalPalletQuantity > item.quantity
          ? "OVER_PACKED"
          : totalPalletQuantity < item.quantity
          ? "UNDER_PACKED"
          : "CORRECTLY_PACKED";

      const palletNames = palletsForItem
        .map((pallet: any) => {
          const palletName = pallet.palletName.toLowerCase();
          if (palletName.includes("mixed")) {
            return "MP" + palletName.match(/\d+/)?.[0] || "";
          } else if (palletName.includes("pallet")) {
            return "P" + palletName.match(/\d+/)?.[0] || "";
          } else {
            return palletName;
          }
        })
        .join(", ");

      // Return the item-wise data
      return {
        itemId: item.id,
        itemName: item.name,
        totalQuantity: item.quantity,
        totalPalletQuantity,
        status,
        palletNames,
      };
    });
  };

  const handleCreatePackingList = async () => {
    const isValid = handlePackingList();

    if (!isValid) return;

    const allCorrectlyPacked = finalItemStatus.every(
      (item: any) => item.status === "CORRECTLY_PACKED"
    );

    if (allCorrectlyPacked) {
      const data = transformPalletData(tableData);
      await completePackingList(data);
      navigate("/packing-list");
      return;
    }

    const discrepanciesList = finalItemStatus
      .filter((item: any) => item.status !== "CORRECTLY_PACKED")
      .map((item: any) => {
        const packed = item.totalPalletQuantity;
        const expected = item.totalQuantity;
        const status = item.status
          .replace(/_/g, "-") // Replace underscores with hyphens
          .toLowerCase()
          .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize the first letter of each word

        return {
          itemName: item.itemName,
          packed,
          expected,
          status,
        };
      });

    setDiscrepancies(discrepanciesList);
    setIsDiscrepancyModalOpen(true);
  };

  const handleConfirmDiscrepancy = async () => {
    const data = transformPalletData(tableData);
    await completePackingList(data);
    // fetchAllCompletedSalesOrder();
    setIsDiscrepancyModalOpen(false);
    navigate("/packing-list");
  };

  const handleCancelDiscrepancy = () => {
    setIsDiscrepancyModalOpen(false);
  };

  useEffect(() => {
    if (packageId && isOpen) {
      fetchItems(packageId);
      // fetchPallets(packageId);
    }
  }, [packageId, isOpen]);

  useEffect(() => {
    if (tableData) {
      const groupedData: any = [];

      // Group pallets by palletName
      const groupedPallets = tableData.reduce((acc, record) => {
        if (!acc[record.palletName]) {
          acc[record.palletName] = [];
        }
        acc[record.palletName].push(record);
        return acc;
      }, {});

      // Process grouped pallets
      Object.entries(groupedPallets).forEach(([palletName, pallets]: any) => {
        // Sort pallets by id (or any other criteria if needed)
        pallets.sort((a: any, b: any) => a.id - b.id);

        // Add each pallet's main row
        pallets.forEach((pallet: any) => {
          const mainRow = {
            ...pallet,
            key: pallet.id,
            rowType: "main",
          };

          // Check if palletType is MIXPALLET and set isEditableRow based on containerType
          if (pallet.palletType === "MIXPALLET") {
            mainRow.isEditableRow = pallet.containerType === "BOX";
          }

          groupedData.push(mainRow);
        });

        // Add a single dimension row for the pallet group
        const dimensions = pallets[0]?.palletDimensions || {};
        groupedData.push({
          key: `${palletName}-dimensions`,
          palletType: pallets[0].palletType,
          palletName: pallets[0].palletName,
          isDimensionsRow: true,
          length: dimensions.palletLength || 0,
          width: dimensions.palletWidth || 0,
          height: dimensions.palletHeight || 0,
          weight: dimensions.palletWeight || 0,
          isEditableRow: pallets[0].palletType === "MIXPALLET",
        });
      });

      // setPalletCounter(
      //   groupedData.filter(
      //     (item: any) =>
      //       item.palletType === "PALLET" && item.isDimensionsRow === false
      //   ).length + 1
      // );
      setTransformedData(groupedData);
    }
  }, [tableData]);

  useEffect(() => {
    if (items.length && tableData.length) {
      const response = getItemWiseData(items, tableData);
      setFinalItemStatus(response);
    }
  }, [items, tableData]);

  return (
    <div className="shipment-table">
      <div className="shipment-table__wrapper">
        <SearchAndFilter
          // salesOrders={selectedShippingOrder}
          onOrderClick={handleOrderClick}
          isOrderStatusModalOpen={isOrderStatusModalOpen}
          onOrderStatusModalClose={handleOrderStatusModalClose}
          onClick={showModal}
          onSaveAndDraft={handleSaveAndDraft}
          packageId={packageId}
          palletsLength={transformedData.length}
          // createPackingList={createPackingListHandler}
          handlePackingList={handlePackingList}
          isSaveAndDraftLoading={isSaveAndDraftLoading}
          finalItemStatus={finalItemStatus}
          isCreatePackingLoading={isCreatePackingLoading}
          discrepancies={discrepancies}
          isDiscrepancyModalOpen={isDiscrepancyModalOpen}
          handleCreatePackingList={handleCreatePackingList}
          handleCancelDiscrepancy={handleCancelDiscrepancy}
          handleConfirmDiscrepancy={handleConfirmDiscrepancy}
          fetchItems={fetchItems}
        />

        <Table
          dataSource={transformedData}
          columns={columns}
          loading={
            palletsLoading || isSaveAndDraftLoading || isCreatePackingLoading
          }
          rowKey={(record) => record.id}
          scroll={{ y: "calc(100vh - 270  px)" }}
          className="shipping-table"
          pagination={false}
          expandable={{
            expandedRowRender: getExpandedRowRender,
            expandedRowKeys,
            onExpand: handleExpand,
            rowExpandable: (record) => !record.isDimensionsRow,
          }}
          rowClassName={(record) =>
            record.isDimensionsRow ? "dimensions-row" : ""
          }
        />
      </div>

      <PalletTypeModal
        isPalletTypeModalOpen={isPalletTypeModalOpen}
        onCancel={closeModal}
        onSave={onSavePalletType}
      />

      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
        isAddUserLoading={isDeleteLoading}
      />
    </div>
  );
};

export default ShippingModal;
